<template>
  <el-drawer title="详情" :visible.sync="show" direction="rtl" size="55%">
    <div class="content">
      <el-descriptions direction="vertical" :column="4" title="订单信息" border>
        <el-descriptions-item label="订单编号">{{ orderInfo?.order_no }}</el-descriptions-item>
        <el-descriptions-item label="下单时间">{{ orderInfo?.created_at }}</el-descriptions-item>
        <el-descriptions-item label="商品名称">{{ orderInfo?.order_goods_item?.goods_name }}</el-descriptions-item>
        <el-descriptions-item label="商品积分">{{ orderInfo?.order_goods_item?.goods_price }}</el-descriptions-item>
        <el-descriptions-item label="积分">{{ orderInfo?.points_num }}</el-descriptions-item>
        <el-descriptions-item label="购买数量">{{ orderInfo?.order_goods_item?.total_num }}</el-descriptions-item>
      </el-descriptions>

      <el-descriptions direction="vertical" :column="4" title="用户信息" border style="margin-top: 30px;" v-if="orderInfo?.user">
        <el-descriptions-item label="用户id">{{ orderInfo?.user?.id }}</el-descriptions-item>
        <el-descriptions-item label="用户昵称"><img class="face" :src="orderInfo.user.face" alt="">{{ orderInfo?.user?.nickname }}</el-descriptions-item>
        <el-descriptions-item label="手机号码">{{ orderInfo?.user?.mobile }}</el-descriptions-item>
      </el-descriptions>


      <el-descriptions direction="vertical" :column="4" title="核销管理员" border style="margin-top: 30px;" v-if="orderInfo?.hexiao_manager">
        <el-descriptions-item label="用户id">{{ orderInfo?.hexiao_manager?.id }}</el-descriptions-item>
        <el-descriptions-item label="用户昵称">{{ orderInfo?.hexiao_manager?.nickname }}</el-descriptions-item>
        <el-descriptions-item label="手机号码">{{ orderInfo?.hexiao_manager?.mobile }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </el-drawer>
</template>

<script>
import {getDetailAPI} from "./api";

export default {
  name: 'Detail',

  data() {
    return {
      show: false,
      orderInfo: {}
    }
  },

  methods: {
    async open(id) {
      this.show = true
      this.orderInfo = await getDetailAPI(id)
    }
  }
}
</script>


<style lang="scss" scoped>
.content {
  padding: 20px;
  .face {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
  }
}
</style>
